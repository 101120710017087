import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import create from 'zustand';
import { useRepository, useTeam } from '../lib/hooks';
import { Repository } from '../lib/repository';
import { Item, ItemType, PurchaseState, UserTransaction } from '../lib/types';
import { CartItem, Product, StoreFilter, StoreOrder, Toast } from '../lib/types.local';

const useUserStore = () => {
  const repo = useRepository();
  const queryClient = useQueryClient();
  const query = useQuery(['me'], () => {
    return repo.getMe();
  });
  const user = query.data;

  const data = useMemo(() => {
    return {
      id: user?.id || '',
      accountId: user?.account_id || '',
      sectionId: user?.team_id || '',
      balance: user?.coins || 0,
      tickets: user?.tickets || 0,
      info: {
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        email: user?.email,
        phone: undefined,
        company: undefined,
      },
      level: user?.level || { number: 1, badge_url: '', level_at: 0, next_level_at: null },
      shipping: user?.shipping || {},
      loadBalance: (repository?: Repository) => {
        queryClient.invalidateQueries(['me']);
      },
    };
  }, [user, queryClient]);

  return data;
};

const [useAlertStore] = create<{
  toasts: Toast[];
  addMessageAsToast: (message: string) => void;
  removeToast: (toast: Toast) => void;
}>((set) => ({
  toasts: [],
  addMessageAsToast: (message: string) => {
    set((state) => {
      const toast = { id: Date.now().toString(), message };
      setTimeout(() => {
        state.removeToast(toast);
      }, 10000);
      return { toasts: state.toasts.concat([toast]) };
    });
  },
  removeToast: (toast) => {
    set((state) => ({ toasts: state.toasts.filter((t) => t !== toast) }));
  },
}));

const useBrandingStore = () => {
  const query = useTeam();
  return {
    ...query,
    loaded: Boolean(query.data),

    has_poweredby: true,
    has_store: true,
    icon_double: null,
    logo: null,
    tickets_icon: null,
    ...query?.data?.branding,
  };
};

const [useBulkRedemptionStore] = create<{
  purchaseIds: string[];
  setPurchaseIds: (ids: string[]) => void;
}>((set, get) => ({
  purchaseIds: [],
  setPurchaseIds: (purchaseIds: string[]) => set({ purchaseIds }),
}));

const useLeaderboardStore = () => {
  const query = useTeam();
  const individualEnabled = Boolean(query.data && query.data.leaderboards.find((l) => l.id === 'individual' && l.enabled));
  const svsEnabled = Boolean(query.data && query.data.leaderboards.find((l) => l.id === 'svs' && l.enabled));

  return {
    ...query,

    loading: query.isLoading,
    loaded: Boolean(query.data),

    anyEnabled: individualEnabled || svsEnabled,
    leaderboardEnabled: individualEnabled,
    svsLeaderboardEnabled: svsEnabled,
  };
};

const [useLoadingOverlay] = create<{
  id: number;
  opened: boolean;
  setOpened: (opened: boolean) => void;
}>((set, get) => ({
  id: 0,
  opened: false,
  setOpened: (opened) => set({ opened, id: get().id + (opened ? 1 : 0) }),
}));

const usePurchasesStore = () => {
  const repo = useRepository();
  const queryClient = useQueryClient();
  const purchasesQuery = useQuery(['all-purchases'], () => repo.getPurchases());
  const lastSeenQuery = useQuery(['last-seen-purchases'], () => repo.getLastSeenPurchases());
  const updateLastSeenMutation = useMutation(() => repo.setLastSeenPurchases(new Date()), {
    onSuccess: () => {
      queryClient.invalidateQueries(['last-seen-purchases']);
    },
  });
  const purchases = purchasesQuery.data || [];
  const lastSeen = lastSeenQuery.data || new Date(0);

  const ts = Math.floor(lastSeen.getTime() / 1000);
  const newPurchasesCount = purchases.filter((p) => p.state === PurchaseState.Made && p.made_on > ts).length;
  const pendingPurchasesCount = purchases.filter((p) => p.state === PurchaseState.Made).length;

  return {
    purchases,
    newPurchasesCount,
    pendingPurchasesCount,
    last_seen: lastSeen,
    loading: purchasesQuery.isLoading,

    loadPurchases: (repository?: Repository) => {
      queryClient.invalidateQueries(['all-purchases']);
      queryClient.invalidateQueries(['last-seen-purchases']);
    },
    updateLastSeen: (repository?: Repository) => {
      updateLastSeenMutation.mutate();
    },
  };
};

const [useRecentActivityStore] = create<{
  items: UserTransaction[];
  loading: boolean;
  loadItems: (repository: Repository) => void;
  setItems: (items: UserTransaction[]) => void;
}>((set) => ({
  items: [],
  loading: false,
  loadItems: async (repository: Repository) => {
    set({ loading: true });
    set({ loading: false, items: await repository.getRecentActivity() });
  },
  setItems: (items: UserTransaction[]) => {
    set({ items });
  },
}));

export const useStoreListingStore = () => {
  const ticketsQuery = useTickets();

  const queryClient = useQueryClient();
  const repository = useRepository();
  const [options, setOptions] = useState<{
    order: StoreOrder;
    filter: StoreFilter;
  }>({
    filter: StoreFilter.All,
    order: StoreOrder.EndingSoon,
  });

  const query = useQuery(['products'], () => repository.getProducts(ticketsQuery.ticketsEnabled ? [] : [ItemType.Sweepstakes]), {
    enabled: !ticketsQuery.isLoading,
  });
  const types = useMemo(() => {
    return (query.data || []).reduce<ItemType[]>((carry, product) => {
      if (!carry.includes(product.type)) {
        return [...carry, product.type];
      }
      return carry;
    }, []);
  }, [query.data]);

  return {
    loaded: query.data,
    loading: query.isLoading,
    products: query.data || [],
    product_types: types,
    filter: options.filter,
    order: options.order,
    setFilter: (filter: StoreFilter) => setOptions({ ...options, filter }),
    setOrder: (order: StoreOrder) => setOptions({ ...options, order }),
    loadProducts: (repository?: Repository) => {
      queryClient.invalidateQueries('products');
    },

    // Deprecated.
    setProducts: (products: Product[]) => {
      queryClient.setQueryData(['products'], products);
    },
  };
};

const [useCartStore] = create<{
  empty: () => void;
  getQuantityOf: (itemId: string) => number;
  items: CartItem[];
  increaseQuantity: (item: Item, quantity: number) => void;
  remove: (itemId: string) => void;
  setQuantity: (itemId: string, quantity: number) => void;
}>((set, get) => ({
  empty: () => set({ items: [] }),
  getQuantityOf: (itemId: string) => {
    return get().items.find((i) => i.id === itemId)?.quantity || 0;
  },
  items: [],
  increaseQuantity: (item: Item, quantity: number) =>
    set((state) => {
      if (item.type !== ItemType.Purchase) return state;

      let found = Boolean(state.items.find((i) => i.id === item.id));
      let newItems;

      if (!found) {
        newItems = state.items.concat([
          {
            id: item.id,
            name: item.name,
            cost: item.cost,
            quantity: Math.min(25, quantity),
            description: item.description,
            thumbnail_url: item.thumbnail_url,
          },
        ]);
      } else {
        newItems = state.items
          .map((i) => {
            if (i.id !== item.id) return i;
            return { ...i, quantity: Math.min(25, i.quantity + quantity) };
          })
          .filter((i) => i.quantity > 0);
      }

      return { items: newItems };
    }),
  setQuantity: (itemId: string, quantity: number) =>
    set((state) => {
      return {
        items: state.items
          .map((i) => {
            if (i.id !== itemId) return i;
            return { ...i, quantity };
          })
          .filter((i) => i.quantity > 0),
      };
    }),
  remove: (itemId: string) => set((state) => ({ items: state.items.filter((i) => i.id !== itemId) })),
}));

export const useTickets = () => {
  const query = useTeam();
  return {
    ...query,
    data: { tickets_enabled: Boolean(query.data?.tickets_enabled) },
    ticketsEnabled: Boolean(query.data?.tickets_enabled),
  };
};

export {
  useAlertStore,
  useBrandingStore,
  useBulkRedemptionStore,
  useCartStore,
  useLeaderboardStore,
  useLoadingOverlay,
  usePurchasesStore,
  useRecentActivityStore,
  useUserStore,
};
