import React from 'react';
import { _ } from '../lib/l18n';
import { logoutUrl, profileUrl } from '../lib/urls';
import { RecentActivityItem } from '../lib/types.local';
import Activity, { ActivityItem, ActivityItemLst } from './Activity';
import PageWithSideMenu, { SideMenuItem } from './layouts/PageWithSideMenu';
import { useIsEmbedded } from '../lib/hooks';

const RecentActivity: React.FC<{ items: RecentActivityItem[]; loading?: boolean }> = ({ items, loading = true }) => {
  const isEmbedded = useIsEmbedded();
  return (
    <PageWithSideMenu
      menuTitle={_('profile')}
      renderMenuItems={() => (
        <>
          <SideMenuItem to={profileUrl}>{_('recentActivity')}</SideMenuItem>
          {!isEmbedded ? <SideMenuItem to={logoutUrl}>{_('logout')}</SideMenuItem> : null}
        </>
      )}
    >
      {!loading ? (
        <Activity>
          <ActivityItemLst>
            {items.map((item) => {
              const key = `${item.date.getTime()}-${item.amount}`;
              return (
                <ActivityItem
                  key={key}
                  date={item.date.toLocaleDateString()}
                  amount={item.amount}
                  label={_('awardType')}
                  value={item.description}
                ></ActivityItem>
              );
            })}
          </ActivityItemLst>
        </Activity>
      ) : null}
    </PageWithSideMenu>
  );
};

export default RecentActivity;
