import queryString from 'query-string';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';
import './assets/tailwind.generated.css';
import Button from './components/Button';
import Footer from './components/Footer';
import { LoginWhileEmbedded } from './components/Login';
import BlankPage from './components/layouts/BlankPage';
import AppLoader from './containers/AppLoader';
import CartContainer from './containers/Cart';
import HeaderContainer from './containers/Header';
import InformationContainer from './containers/Information';
import LeaderboardContainer from './containers/Leaderboard';
import LoadingOverlayContainer from './containers/LoadingOverlay';
import LoadingScreenContainer from './containers/LoadingScreen';
import LoggedOutContainer from './containers/LoggedOut';
import LoginContainer from './containers/Login';
import LogoutContainer from './containers/Logout';
import ProductContainer from './containers/Product';
import PurchaseBulkRedeemContainer from './containers/PurchaseBulkRedeem';
import PurchaseRedeemContainer from './containers/PurchaseRedeem';
import PurchaseThankYouContainer from './containers/PurchaseThankYou';
import RecentActivityContainer from './containers/RecentActivity';
import RedemptionsContainer from './containers/Redemptions';
import StoreContainer from './containers/Store';
import ToasterContainer from './containers/Toaster';
import { AccessToken, AuthenticationManager, BasicAuthenticationManager, PersistAuthenticationManager } from './lib/auth';
import { RepositoryContext, RootActionsContext } from './lib/contexts';
import { useIsEmbedded } from './lib/hooks';
import { _ } from './lib/l18n';
import { AUTHENTICATION_ERROR, JwtRepository, Repository } from './lib/repository';
import {
  cartUrl,
  getAbsoluteAuthUrl,
  getAbsoluteStoreUrl,
  getApiRootUrl,
  infoUrl,
  leaderboardUrl,
  leaderboardUrlPaths,
  logoutUrl,
  productUrlPath,
  profileUrl,
  purchaseBulkRedeemThankYouUrl,
  purchaseBulkRedeemUrl,
  purchaseRedeemPath,
  purchaseThankYouPath,
  redemptionsUrlPaths,
  storeUrl,
} from './lib/urls';
import { isEmailValid } from './lib/validation';
import { useBrandingStore, useLeaderboardStore } from './state/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 1000 * 60, cacheTime: 1000 * 60 * 15 },
  },
});

type RootState = { step: string; repo: JwtRepository | null; defaultLoginEmail?: string };
type RootProps = { authManager: AuthenticationManager; isEmbedded: boolean };

class Root extends React.Component<RootProps, RootState> {
  constructor(props: RootProps) {
    super(props);
    this.state = {
      step: 'init',
      repo: null,
    };
  }

  componentDidMount() {
    this.handleStep(this.state.step);
  }

  componentDidUpdate(prevProps: RootProps, prevState: RootState) {
    if (prevState.step !== this.state.step) {
      this.handleStep(this.state.step);
    }
  }

  handleAuthenticationError = () => {
    this.setState({ step: 'auth-error' });
  };

  handleLogout = async () => {
    this.setState({ step: 'logout' });
  };

  handleStep = (step: string) => {
    switch (step) {
      case 'auth-error':
        return this.handleStepAuthError();
      case 'init':
        return this.handleStepInit();
      case 'restore-login':
        return this.handleStepRestoreLogin();
      case 'logout':
        return this.handleStepLogout();
      default:
        break;
    }
  };

  handleStepAuthError = () => {
    this.handleLogout();
  };

  handleStepInit = async () => {
    const queryParams = queryString.parse(window.location.search);
    if (typeof queryParams.authsecret === 'string') {
      window.history.replaceState(null, '', window.location.pathname);
      const at = await this.props.authManager.getTokenFromSecret(queryParams.authsecret);
      if (!at) {
        this.setState({ step: 'restore-login' });
        return;
      }
      this.handleSuccessfulLogin(at);
    } else {
      let defaultLoginEmail;
      if (window.location.pathname === '/' && typeof queryParams.email === 'string' && isEmailValid(queryParams.email)) {
        defaultLoginEmail = queryParams.email;
        window.history.replaceState(null, '', window.location.pathname);
      }
      this.setState({ step: 'restore-login', defaultLoginEmail });
    }
  };

  handleStepRestoreLogin = async () => {
    let accessToken = await this.props.authManager.getSavedToken();
    if (accessToken && this.props.authManager.shouldRefreshToken(accessToken)) {
      const refreshedToken = await this.props.authManager.refreshToken(accessToken);
      if (!refreshedToken) {
        await this.props.authManager.removeToken();
      }
      accessToken = refreshedToken;
    }
    if (!accessToken) {
      this.setState({ step: 'login' });
      return;
    }
    this.handleSuccessfulLogin(accessToken);
  };

  handleStepLogout = async () => {
    try {
      if (this.state.repo) {
        this.props.authManager.revokeToken(this.state.repo.getAccessToken());
      }
    } catch (err) {
      console.log('Exception while revoking the token', err);
    }

    this.setState({ repo: null, step: 'loggedout' });
  };

  handleSuccessfulLogin = async (token: AccessToken) => {
    this.props.authManager.saveToken(token);
    let repo;
    try {
      repo = new JwtRepository(getApiRootUrl(), token, this.props.authManager);
      repo.addListener(AUTHENTICATION_ERROR, this.handleAuthenticationError);
    } catch {
      await this.props.authManager.removeToken();
      this.setState({ step: 'login', repo: null });
      return;
    }
    this.setState({ step: 'loggedin', repo });
  };

  performLogin = () => {
    this.setState({ step: 'init', repo: null });
  };

  performLogout = () => {
    this.setState({ step: 'logout' });
  };

  sendAuthenticationEmail = async (email: string): Promise<void> => {
    try {
      await this.props.authManager.sendAuthenticationEmail(email);
    } catch (err) {
      throw new Error(typeof err === 'string' ? err : err.message || _('error.unknown'));
    }
  };

  renderContent() {
    if (this.state.step === 'init') {
      return <LoadingScreenContainer />;
    } else if (this.state.step === 'restore-login') {
      return <LoadingScreenContainer />;
    } else if (this.state.step === 'login') {
      if (this.props.isEmbedded) {
        return <LoginWhileEmbedded />;
      }
      return <LoginContainer defaultEmail={this.state.defaultLoginEmail} />;
    } else if (this.state.step === 'logout' || this.state.step === 'loggedout') {
      if (this.props.isEmbedded) {
        return <LoginWhileEmbedded />;
      }
      return <LoggedOutContainer />;
    } else if (this.state.step === 'loggedin') {
      return <App repository={this.state.repo as Repository} />;
    }

    return (
      <BlankPage>
        <div className="flex flex-1 justify-center items-center flex-col">
          <div className="max-w-sm">
            <p className="text-center mb-8 text-grey-slate">{_('error.unknown')}</p>
            <p>
              <Button primary onClick={() => this.setState({ step: 'init', repo: null })}>
                Try again
              </Button>
              <Redirect to="/" />
            </p>
          </div>
        </div>
      </BlankPage>
    );
  }

  renderRedirect() {
    // This is to lose the URL when needed.
    if (this.state.step === 'logout' || this.state.step === 'loggedout') {
      return <Redirect to="/" />;
    }
    return null;
  }

  render() {
    return (
      <RootActionsContext.Provider
        value={{
          performLogin: this.performLogin,
          performLogout: this.performLogout,
          sendAuthenticationEmail: this.sendAuthenticationEmail,
        }}
      >
        {this.renderContent()}
        {this.renderRedirect()}
      </RootActionsContext.Provider>
    );
  }
}

const App: React.FC<{ repository: Repository }> = ({ repository }) => {
  return (
    <RepositoryContext.Provider value={repository}>
      <QueryClientProvider client={queryClient}>
        <AppLoader>
          <AppContent />
        </AppLoader>
      </QueryClientProvider>
    </RepositoryContext.Provider>
  );
};

function AppContent() {
  const { has_store: showStore } = useBrandingStore();
  return (
    <>
      <HeaderContainer />
      <div className="mt-8"></div>
      <div className="flex flex-grow">
        <Switch>
          <Route path={profileUrl}>
            <RecentActivityContainer />
          </Route>
          <Route path={leaderboardUrlPaths}>
            <LeaderboardContainer />
          </Route>
          <Route path={infoUrl}>
            <InformationContainer />
          </Route>

          {showStore ? (
            <Route path={purchaseBulkRedeemUrl} exact>
              <PurchaseBulkRedeemContainer />
            </Route>
          ) : null}
          {showStore ? (
            <Route path={purchaseBulkRedeemThankYouUrl}>
              <PurchaseThankYouContainer />
            </Route>
          ) : null}
          {showStore ? (
            <Route path={purchaseRedeemPath}>
              <PurchaseRedeemContainer />
            </Route>
          ) : null}
          {showStore ? (
            <Route path={purchaseThankYouPath}>
              <PurchaseThankYouContainer />
            </Route>
          ) : null}
          {showStore ? (
            <Route path={redemptionsUrlPaths}>
              <RedemptionsContainer />
            </Route>
          ) : null}

          {showStore ? (
            <Route path={cartUrl}>
              <CartContainer />
            </Route>
          ) : null}
          {showStore ? (
            <Route path={productUrlPath}>
              <ProductContainer />
            </Route>
          ) : null}
          {showStore ? (
            <Route path={storeUrl} exact>
              <StoreContainer />
            </Route>
          ) : null}
          <Route path={logoutUrl}>
            <LogoutContainer />
          </Route>
          <Route>
            <DefaultRouteRedirect />
          </Route>
        </Switch>
      </div>
      <div className="mt-8"></div>
      <Footer />
      <ToasterContainer />
      <LoadingOverlayContainer />
    </>
  );
}

const DefaultRouteRedirect = () => {
  const { has_store: showStore } = useBrandingStore();
  const { anyEnabled: showLeaderboard } = useLeaderboardStore();
  if (showStore) {
    return <Redirect to={storeUrl} />;
  } else if (showLeaderboard) {
    return <Redirect to={leaderboardUrl} />;
  }
  return <Redirect to={infoUrl} />;
};

const Top = () => {
  const isEmbedded = useIsEmbedded();
  const authManager = isEmbedded
    ? new BasicAuthenticationManager(getAbsoluteAuthUrl(), getAbsoluteStoreUrl())
    : new PersistAuthenticationManager(getAbsoluteAuthUrl(), getAbsoluteStoreUrl());
  return <Root authManager={authManager} isEmbedded={isEmbedded} />;
};

export default Top;
